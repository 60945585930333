import { NgModule } from '@angular/core';


import { MatIconModule } from "@angular/material/icon";
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {PortalModule} from '@angular/cdk/portal';
import { MatButtonModule } from "@angular/material/button";
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  imports: [

    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    PortalModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatFormFieldModule


  ],

  exports: [
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    PortalModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatFormFieldModule

  ]
})
export class MaterialModule {}

