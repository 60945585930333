import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Injectable()

export class Service {



  animationToggle: BehaviorSubject<boolean> = new BehaviorSubject(true);
  toggle$ = this.animationToggle.asObservable();

  animationdirection: BehaviorSubject<boolean> = new BehaviorSubject(true);
  direct$ = this.animationdirection.asObservable();

  animationToggleNew: BehaviorSubject<boolean> = new BehaviorSubject(true);
  show$ = this.animationToggleNew.asObservable();

  mobilMobilMenu: BehaviorSubject<boolean> = new BehaviorSubject(true);
  playMobilMobil$ = this.mobilMobilMenu.asObservable();


  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  public hideNshowMservice = new Subject<any>();
  sendhideNshowMlinks() {
    this.hideNshowMservice.next();
  }
  gethideNshowMlinks(): Observable<any>{
    return this.hideNshowMservice.asObservable();
  }

  public hideNshowHeaderservice = new Subject<any>();
  sendhideNshowHeader() {
    this.hideNshowHeaderservice.next();
  }
  gethideNshowHeader(): Observable<any>{
    return this.hideNshowHeaderservice.asObservable();
  }



}
