<section class="sectionText pageInner">

    <div class="innerScroll" fxLayout="column" fxLayoutAlign="center center">

      <br><br>
      <img class="title" src="../../assets/new-tit-bulentS.png" />

      <br><br>

      <img class="p2" src="../../../assets/introToBulo.png" />
      <br><br>
      <p class="p2" #text i18n [innerHTML]="'mainmenu.bulent-t1' | translate"></p>
      <br><br>

      <img class="p2" src="../../../assets/bulentLegal.png" />
      <br><br>

      <p class="p2" i18n [innerHTML]="'mainmenu.bulent-t2' | translate"></p>
      <br><br>
      <img class="p2" src="../../../assets/bulent2li.png" />

      <br><br>

      <p class="p3">
        <span i18n [innerHTML]="'mainmenu.bulent-t3' | translate"></span>
        <span i18n [innerHTML]="'mainmenu.bulent-t4' | translate"></span>
        <a class='selctbl' target='_blank' href='../../../assets/bulentersoy-timecode.txt' i18n [innerHTML]="'mainmenu.bulent-t5' | translate"></a>
        <span i18n [innerHTML]="'mainmenu.bulent-t6' | translate"></span>
      </p>

       <br><br><br><br>

    </div>


</section>
