import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Service } from '../../service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})




export class HeaderComponent implements OnInit, OnDestroy {




  @Output() sidenavToggle = new EventEmitter<void>();


  @Input() logging = false;
  isOpen = true;
  public hide = false;

  hideNshowHeaderSubscription:Subscription;
  hideNshowHeader(){this.hide = !this.hide}

  message:string;
  subscription: Subscription;

  constructor(private service: Service, private route:Router, public translate: TranslateService) {
    this.hideNshowHeaderSubscription=this.service.gethideNshowHeader().subscribe(()=>{ this.hideNshowHeader(); });
    this.service.sendhideNshowMlinks();

    translate.addLangs(['EN', 'TR', 'DE']);
    translate.setDefaultLang('TR');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/EN|TR/) ? browserLang : 'EN');
  }

  linkPoster(){ setTimeout (() => { this.route.navigate(['/anasahne']); }, 850); };
  linkHuysuz(){ setTimeout (() => { this.route.navigate(['huysuzvirjin']); }, 850); };
  linkZeki(){ setTimeout (() => { this.route.navigate(['/zekimuren']); }, 850); };
  linkBulent(){ setTimeout (() => { this.route.navigate(['/bulentersoy']); }, 850); };
  linklubunya(){ setTimeout (() => { this.route.navigate(['/lubunya']); }, 850); };
  linklubun(){ setTimeout (() => { this.route.navigate(['/lubun']); }, 850); };
  linkPodcast(){ setTimeout (() => { this.route.navigate(['/podkest']); }, 850); };
  linkMadi(){ setTimeout (() => { this.route.navigate(['/madilik']); }, 850); };
  linkgazino(){ setTimeout (() => { this.route.navigate(['/gazino']); }, 850); };



  startFillerAnimation() {
    this.isOpen = !this.isOpen;
    this.service.animationToggle.next(this.isOpen);
  }




  onToggleSidenav(){

    this.service.mobilMobilMenu.next(true); //fon
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('menuOn')
    setTimeout (() => { this.sidenavToggle.emit();  },800);//sidenav acmak
    setTimeout (() => { this.service.sendhideNshowMlinks();   },1100);//link anime


  }

  ngOnInit() {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }






}
