<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)" #fullpageRef>


  <div class="section"> <!-- leman -->
    <div class="lubunyaFrame leman" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="lemansevdadaricıoglu">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-leman.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-leman.png"
        style="margin-top: -117px;padding-left: 74px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-leman' | translate"></p>
        <a class="bioLink" href="http://lemandaricioglu.com" target="_blank">www.lemandaricioglu.com</a>
      </div>
    </div>
    <img fxHide.xs fxHide.xl class="poptitle" src="../../assets/b-title-leman.png" />
    <img fxHide fxShow.xl class="poptitle" src="../../assets/b-title-leman2.png" />
  </div>


  <div class="section"> <!-- yener -->
    <div class="lubunyaFrame yener" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="yenerbayramoglu">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-yener.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-yener.png"
        style="margin-top: -117px;padding-left: 74px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-yener' | translate"></p>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-yener.png" />
  </div>


  <div class="section"> <!-- gizem -->
    <div class="lubunyaFrame gizem" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="gizemoruc">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-gizem.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-gizem.png" style="margin-top: -115px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p>
          <span [innerHTML]="'mainmenu.lubunya-gizem1' | translate"></span>
          <a class='bioLink' href='www.6zm.xyz'  target='_blank'>www.6zm.xyz</a>
          <span [innerHTML]="'mainmenu.lubunya-gizem2' | translate"></span>
          <a class='bioLink' href='www.gazino.de' target='_blank'>www.gazino.de</a>
          <span [innerHTML]="'mainmenu.lubunya-gizem3' | translate"></span>
          <a class="bioLink" href="www.gizemoruc.net" target="_blank">www.gizemoruc.net</a>
        </p>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-gizem.png" />
  </div>


  <div class="section"> <!-- ekin -->
    <div class="lubunyaFrame ekin" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="ekinyasa">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-ekin.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-ekin.png"
        style="margin-top: -86px; padding-left: 200px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p>
          <span [innerHTML]="'mainmenu.lubunya-ekin1' | translate"></span>
          <a class="bioLink" href="www.bedencalismalari.com"  target="_blank">www.bedencalismalari.com</a>
          <span [innerHTML]="'mainmenu.lubunya-ekin2' | translate"></span>
          <a class="bioLink" href="www.sodacommunicationdesign.com"  target="_blank">www.sodacommunicationdesign.com</a>
          <span [innerHTML]="'mainmenu.lubunya-ekin3' | translate"></span>
        </p>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-ekin.png" />
  </div>


  <div class="section"> <!-- anthony -->
    <div class="lubunyaFrame ant" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="anthonyhuseyin">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-anthony.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-anthony.png"
        style="margin-top: -77px;max-width: 400px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-ant' | translate"></p>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-anthony.png" />
  </div>


  <div class="section"> <!-- ari -->
    <div class="lubunyaFrame ari" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="aribuyuktas">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-ari.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-ari.png"
        style="margin-top: -77px;max-width: 440px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p>
          <span  [innerHTML]="'mainmenu.lubunya-ari1' | translate"></span>
          <a class="bioLink" href="http://www.velvele.net" target="_blank"><strong>Velvele.net</strong></a>
          <span  [innerHTML]="'mainmenu.lubunya-ari2' | translate"></span>
        </p>
        <a class="bioLink" href="http://www.velvele.net" target="_blank">velvele.net</a>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-ari.png" />
  </div>


  <div class="section"> <!-- besire -->
    <div class="lubunyaFrame besire" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="besireparalik">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-besire.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-besire.png"
        style="margin-top: -77px;padding-right: 45px; max-width: 430px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-besire' | translate"></p>
        <a class="bioLink" href="vimeo.com/paralik" target="_blank">vimeo.com/paralik</a><br>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-besire.png" />
  </div>



  <div class="section"> <!-- gulay -->
    <div class="lubunyaFrame gulay" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="gulayakin">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-gulay.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-gulay.png" style="margin-top: -77px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-gulay' | translate"></p>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-gulay.png" />
  </div>


  <div class="section"> <!-- ilgaz -->
    <div class="lubunyaFrame ilgaz" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="ilgazyalcinoglu">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-ilgaz.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-ilgaz.png" style="margin-top: -77px;max-width: 375px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-ilgaz' | translate"></p>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-ilgaz.png" />
  </div>


  <div class="section"> <!-- ipek -->
    <div class="lubunyaFrame ipek" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="ipekipekcioglu">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-ipek.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-ipek.png" style="margin-top: -77px; max-width: 380px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p>
          <span  [innerHTML]="'mainmenu.lubunya-ipek1' | translate"></span>
          <a class="bioLink" href="www.gladt.de" target="_blank"><strong>Gladt e.V.</strong></a>
          <span  [innerHTML]="'mainmenu.lubunya-ipek3' | translate"></span>
        </p>
          <a class="bioLink" href="www.djipek.com" target="_blank">www.djipek.com</a><br><br>
          DJ IPEK Sounddesign & Booking & Artist Management İpek İpekçioğlu
          <a class="bioLink" href="" target="_blank">Facebook</a> /
          <a class="bioLink" href="" target="_blank">Twitter</a> /
          <a class="bioLink" href="https://www.youtube.com/user/djipek" target="_blank">youtube</a> /
          <a class="bioLink" href="" target="_blank">Soundcloud</a> /
          <a class="bioLink" href="https://ra.co/dj/djipek" target="_blank">Ra</a> /
          <a class="bioLink" href="" target="_blank">mail@djipek.com</a> /
          <a class="bioLink" href="" target="_blank">Bandcamp</a> /
          <br><br>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-ipek.png" />
  </div>


  <div class="section"> <!-- merve -->
    <div class="lubunyaFrame merve" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="mervenamli">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-merve.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-merve.png" style="margin-top: -77px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-merve' | translate"></p>
        <a class="bioLink" href="https://blogs.taz.de/dontwannabetoocool/" target="_blank">blogs.taz.de/dontwannabetoocool</a><br><br>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-merve.png" />
  </div>


  <div class="section"> <!-- pirilti -->
    <div class="lubunyaFrame pirilti" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="piriltionukar">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-pirilti.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-pirilti.png" style="margin-top: -77px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-pirilti' | translate"></p>
        <a class="bioLink" href="https://vimeo.com/pirilti" target="_blank">vimeo.com/pirilti</a><br>
      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-pirilti.png" />
  </div>


  <div class="section"> <!-- tulin -->
    <div class="lubunyaFrame tulin" fxLayout.xs="column" fxLayoutAlign.xs="start center" fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-evenly center" data-anchor="tulayduman">
      <img class="popimage" fxFlex.gt-xs='41' src="../../assets/a-lubunyalar-tulin.png" />
      <img fxHide.gt-xs class="popMtitle" src="../../assets/b-title-tulin.png" style="margin-top: -77px;" />
      <div fxFlex.gt-xs='40' class="biotext scrollme">
        <p [innerHTML]="'mainmenu.lubunya-tulin' | translate"></p>
        <a class="bioLink" href="https://soundcloud.com/queeriental" target="_blank">T**lin (queeriental) - soundcloud.com</a><br>
        <a class="bioLink" href="https://www.facebook.com/queeriental" target="_blank">Queeriental - facebook.com</a><br>


      </div>
    </div>
    <img fxHide.xs class="poptitle" src="../../assets/b-title-tulin.png" />
  </div>

  </div>
