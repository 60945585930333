import { Component, OnInit, Renderer2, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { one } from'../blowAnimation'
import { Subscription } from 'rxjs';
import { Service } from '../service';


@Component({
  selector: 'app-lubunya',
  templateUrl: './lubunya.component.html',
  styleUrls: ['./lubunya.component.css'],
  animations: [one]
})
export class LubunyaComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  @ViewChild('fullpageRef') fp_directive: ElementRef;
  config;
  fullpage_api;
  public screenHeight: any;

  constructor(private renderer: Renderer2, private service: Service) {




        this.config = {

      menu: '#menu',
      navigation: true,
      showActiveTooltip: false,
      slidesNavigation: true,
      slidesNavPosition: 'bottom',
      sectionSelector: '.section',
      slideSelector: '.slide',
      normalScrollElements: '.scrollme',

      //Navigation

      anchors: ['lubunya/#lemansevdadaricıoglu', 'lubunya/#yenerbayramoglu', 'lubunya/#gizemoruc', 'lubunya/#ekinyasa', 'lubunya/#anthonyhuseyin', 'lubunya/#aribuyuk', 'lubunya/#besireparalik', 'lubunya/#gulayakin', 'lubunya/#ilgazyalcinoglu', 'lubunya/#ipekipekcioglu', 'lubunya/#mervenamli', 'lubunya/#piriltionukar', 'lubunya/#tulayduman'],

      navigationPosition: 'left',
      navigationTooltips: ['Leman Sevda Darıcıoğlu', 'Yener Bayramoğlu', 'Gizem Oruç', 'Ekin Yaşa', 'Anthony Hüseyin', 'Ari P. Büyüktaş', 'Besire Paralik', 'Gülây Akın', 'Ilgaz Yalçınoğlu', 'İpek İpekçioğlu', 'Merve Namlı', 'Pırıltı Onukar', 'Tülay Duman'],




      //Scrolling
      css3: true,
      scrollingSpeed: 700,
      autoScrolling: true,
      fitToSection: true,
      fitToSectionDelay: 1000,
      scrollBar: false,
      easing: 'easeInOutCubic',
      easingcss3: 'ease',

      //Accessibility
      keyboardScrolling: true,
      animateAnchor: true,
      recordHistory: true,

      //Design
      controlArrows: true,
      verticalCentered: true,


      lazyLoading: true,


    };



  }

  ngOnInit() {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("lubunya");
    this.screenHeight = window.innerHeight;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }





}

