import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Service } from '../../service';
import {Router} from '@angular/router';
import { trigger, transition, animate, style, state, keyframes, query, stagger } from '@angular/animations';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css'],
  animations: [

    trigger('fillerAnimateGif', [
      transition('open => closed', [animate('1s 0.3s cubic-bezier(.72,.01,.03,.87)',
        keyframes([

          style({ transform: 'translate(0%, -120%) matrix(1, 0, 0, 1, 0, 0)', opacity: 1 }),
          style({ transform: 'translate(0%, -60%) matrix(7.9, 1, 0, 3.7, 0, 0)',opacity: 1 }),
          style({ transform: 'translate(0%, 100%) matrix(1, 0.1, 0, 1, 0, 0)',opacity: 1 }),
          style({ transform: 'translate(0%, 100%) matrix(1, 0.1, 0, 1, 0, 0)',opacity: 0 })
       ])
      )]),

      transition('closed => open', [animate('1s 0.3s cubic-bezier(.72,.01,.03,.87)',
        keyframes([
          style({ transform: 'translate(0%, -120%) matrix(1, 0, 0, 1, 0, 0)',opacity: 1 }),
          style({ transform: 'translate(0%, -60%) matrix(7.9, 1, 0, 3.7, 0, 0)',opacity: 1 }),
          style({ transform: 'translate(0%, 100%) matrix(1, 0.1, 0, 1, 0, 0)',opacity: 1 })
        ])
      )])
    ]),


    trigger('filterAnimation', [
      transition(':enter, * => 0, * => -1', []),
      transition(':increment', [
        query(':enter', [
          style({ opacity: 0, width: '0px' }),
          stagger(50, [
            animate('300ms ease-out', style({ opacity: 1, width: '*' })),
          ]),
        ], { optional: true })
      ]),
      transition(':decrement', [
        query(':leave', [
          stagger(50, [
            animate('300ms ease-out', style({ opacity: 0, width: '0px' })),
          ]),
        ])
      ]),
    ]),

    trigger('flip', [
      state('front', style({
        transform: 'rotateY(0deg)'
      })),
      state('back', style({
        transform: 'rotateY(90deg)'
      })),
      transition('* => void', [
        animate('0.3s 0s ease-out',
          keyframes([
            style({
              transform: 'perspective(300px) rotateY(0deg)' ,opacity: 1
            }),
            style({
              transform: 'perspective(290px) rotateY(22deg)' ,opacity: 0.75
            }),
            style({
              transform: 'perspective(280px) rotateY(45deg)' ,opacity: 0.50
            }),
            style({
              transform: 'perspective(270px) rotateY(68deg)' ,opacity: 0.25
            }),
            style({
              transform: 'perspective(260px) rotateY(90deg)' ,opacity: 0
            })

          ]))
      ]),

      // acılıan menu
      transition('void => *', [
        animate('0.3s 0s ease-in',
          keyframes([
            style({
              transform: 'perspective(400px) rotateY(90deg)', opacity: 0
            }),
            style({
              transform: 'perspective(390px) rotateY(68deg)', opacity: 0.25
            }),
            style({
              transform: 'perspective(380px) rotateY(45deg)', opacity: 0.50
            }),
            style({
              transform: 'perspective(370px)  rotateY(22deg)', opacity: 0.75
            }),
            style({
              transform: 'perspective(360px)  rotateY(0deg)', opacity: 1
            })
          ]))
      ])

    ]),


trigger('flyInOut', [
  state('front', style({
    transform: 'translateX(0vh)'
  })),
  state('back', style({
    transform: 'translateX(2vh)'
  })),
  // acılıan linkler
  transition('* => void', [
    animate('0.5s cubic-bezier(.215,.61,.355,1)',
      keyframes([
        style({
          transform: 'translateX(0vh)' ,opacity: 1,
          offset: 0
        }),
        style({
          transform: 'translateX(-1vh)' ,opacity: 0.50,
          offset: 0.4
        }),
        style({
          transform: 'translateX(-2vh)' ,opacity: 0,
          offset: 0.5
        })
      ]))
  ]),

  // acılıan linkler
  transition('void => *', [
    animate('0.5s 0s ease-in',
      keyframes([
        style({
          transform: 'translateX(2vh)', opacity: 0,
          offset: 0
        }),
        style({
          transform: 'translateX(1vh)', opacity: 0.55,
          offset: 0.4
        }),
        style({
          transform: 'translateX(0vh))', opacity: 1,
          offset: 0.5
        })


      ]))
  ])

]),



  ]

})
export class SidenavListComponent implements OnInit, OnDestroy {


  public animatePage = true;
  public show = false;
  message:string;
  subscription: Subscription;

  @Output() closeSidenav = new EventEmitter<void>();
  @Input() logging = false;

  isOpen = false;

  hideNshowMsubscription:Subscription;
  hideNshowMlinks(){this.show = !this.show}



  image1 = './../../../assets/giphyZeki.gif';
  image2 = './../../../assets/giphyBulent.gif';
  image3 = './../../../assets/giphyHuysuz.gif';
  image4 = './../../../assets/giphyZ2.gif';
  image5 = './../../../assets/giphyB2.gif';
  image6 = './../../../assets/giphyH2.gif';
  image7 = './../../../assets/giphyZ3.gif';
  image8 = './../../../assets/giphyB3.gif';
  image9 = './../../../assets/giphyH3.gif';
  image10 = './../../../assets/giphyZ4.gif';
  image11 = './../../../assets/giphyB4.gif';
  image12 = './../../../assets/giphyH4.gif';
  image13 = './../../../assets/giphyZ5.gif';
  image14 = './../../../assets/giphyB5.gif';


  backgroundImage = this.image1;

  constructor(private service: Service, private route:Router, public translate: TranslateService) {
    this.hideNshowMsubscription=this.service.gethideNshowMlinks().subscribe(()=>{ this.hideNshowMlinks(); })


    translate.addLangs(['EN','DE', 'TR']);
    translate.setDefaultLang('TR');

    const browser2Lang = translate.getBrowserLang();
    translate.use(browser2Lang.match(/EN|TR/) ? browser2Lang : 'EN');
   }

  ngOnInit() {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.hideNshowMlinks();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  startFillerAnimation() {
    this.isOpen = !this.isOpen;
    this.service.animationToggle.next(this.isOpen);
  }



  onClose(){
    this.hideNshowMlinks();
    setTimeout (() => {
      this.closeSidenav.emit();
      this.service.mobilMobilMenu.next(false);
     },100);

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('menuOn');

     if(this.backgroundImage==this.image1 ){this.backgroundImage = this.image2;} else
     if(this.backgroundImage==this.image2 ){this.backgroundImage = this.image3;} else
     if(this.backgroundImage==this.image3 ){this.backgroundImage = this.image4;} else
     if(this.backgroundImage==this.image4 ){this.backgroundImage = this.image5;} else
     if(this.backgroundImage==this.image5 ){this.backgroundImage = this.image6;} else
     if(this.backgroundImage==this.image6 ){this.backgroundImage = this.image7;} else
     if(this.backgroundImage==this.image7 ){this.backgroundImage = this.image8;} else
     if(this.backgroundImage==this.image8 ){this.backgroundImage = this.image9;} else
     if(this.backgroundImage==this.image9 ){this.backgroundImage = this.image10;} else
     if(this.backgroundImage==this.image10 ){this.backgroundImage = this.image11;} else
     if(this.backgroundImage==this.image11 ){this.backgroundImage = this.image12;} else
     if(this.backgroundImage==this.image12 ){this.backgroundImage = this.image13;} else
     if(this.backgroundImage==this.image13 ){this.backgroundImage = this.image14;} else
     if(this.backgroundImage==this.image14 ){this.backgroundImage = this.image1;}

  }

  onAnimationEvent( event: AnimationEvent ) {
    if (!this.logging) {
      return;
    }
  }


  linkPoster(){ setTimeout (() => { this.route.navigate(['anasahne']); }, 100); };
  linkHuysuz(){ setTimeout (() => { this.route.navigate(['huysuzvirjin']); }, 100); };
  linkZeki(){ setTimeout (() => { this.route.navigate(['/zekimuren']); }, 100); };
  linkBulent(){ setTimeout (() => { this.route.navigate(['/bulentersoy']); }, 100); };
  linkLubunya(){ setTimeout (() => { this.route.navigate(['/lubunya']); }, 100); };
  linkLubun(){ setTimeout (() => { this.route.navigate(['/lubun']); }, 100); };
  linkPodcast(){ setTimeout (() => { this.route.navigate(['/podkest']); }, 100); };
  linkMadi(){ setTimeout (() => { this.route.navigate(['/madilik']); }, 100); };
  linkgazino(){ setTimeout (() => { this.route.navigate(['/gazino']); }, 100); };



}


