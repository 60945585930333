import { trigger, transition, animate, style, query, stagger } from '@angular/animations';



export const one =
  trigger('pageAnimations', [
    transition(':enter', [
      query('.pageInner', [
        style({opacity: 0, transform: 'translateY(-1vh)'}),
        stagger(-30, [
          animate('1900ms cubic-bezier(.56,-0.71,0,1.04)', style({ opacity: 1, transform: 'none'}))
        ])
      ])
    ])
  ]);

  export const two =
  trigger('filterAnimation', [


    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
      query(':enter', [
        style({ opacity: 0, width: '0px' }),
        stagger(50, [
          animate('300ms ease-in', style({ opacity: 1, width: '*' })),
        ]),
      ], { optional: true })
    ]),
    transition(':decrement', [
      query(':leave', [
        stagger(50, [
          animate('300ms ease-in', style({ opacity: 0, width: '0px' })),
        ]),
      ])
    ]),

  ]);
