import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';




  import { BulentComponent } from './gazino/bulent/bulent.component';
  import { HuysuzComponent } from './gazino/huysuz/huysuz.component';
  import { ZekiComponent } from './gazino/zeki/zeki.component';
  import { PosterComponent } from './gazino/poster/poster.component';
  import { LubunyaComponent } from './lubunya/lubunya.component';
  import { LubunComponent } from './lubun/lubun.component';
  import { MadilikComponent } from './madilik/madilik.component';
  import { PodcastsComponent } from './podcasts/podcasts.component';
  import { Page404Component } from './page404/page404.component';

const routes: Routes = [

  { path: '',   redirectTo: '/anasahne', pathMatch: 'full' },
  { path: 'anasahne', component: PosterComponent},
  { path: '/anasahne', component: PosterComponent},
  { path: 'madilik', component: MadilikComponent },
  { path: '/madilik', component: MadilikComponent },
  { path: 'zekimuren', component: ZekiComponent },
  { path: '/zekimuren', component: ZekiComponent },
  { path: 'huysuzvirjin', component: HuysuzComponent },
  { path: '/huysuzvirjin', component: HuysuzComponent },
  { path: 'bulentersoy', component: BulentComponent },
  { path: '/bulentersoy', component: BulentComponent },
  { path: 'podkest', component: PodcastsComponent },
  { path: '/podkest', component: PodcastsComponent },
  { path: 'lubunya', component: LubunyaComponent },
  { path: '/lubunya', component: LubunyaComponent },
  { path: 'lubun', component: LubunComponent },
  { path: '/lubun', component: LubunComponent },

  { path: '**', component: Page404Component },  // Wildcard route for a 404 page
];


const config = {useHash:true,enableTracing:true};


@NgModule({
  imports:[RouterModule.forRoot(routes,config)],
  exports: [RouterModule]
})


export class AppRoutingModule {

  }
