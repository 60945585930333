import { Component, OnInit, Input } from '@angular/core';
import { trigger,transition, style, animate, AnimationEvent, keyframes } from '@angular/animations';
import { Service } from '../service';

@Component({
  selector: 'app-filler-animation',
  templateUrl: './filler-animation.component.html',
  styleUrls: ['./filler-animation.component.css'],
  animations: [
    trigger('fillerAnimate', [
      transition('open => closed', [animate('1.5s 0.3s cubic-bezier(.25,.1,.25,1)',
        keyframes([

          style({ transform: 'translate(0%, -120%) matrix(1, 0, 0, 1, 0, 0)',opacity: 0, zIndex: -99999 }),
          style({ transform: 'translate(0%, -120%) matrix(1, 0, 0, 1, 0, 0)',opacity: 1, zIndex: 999 }),
          style({ transform: 'translate(0%, -60%) matrix(7.9, 1, 0, 3.7, 0, 0)',opacity: 1, zIndex: 999 }),
          style({ transform: 'translate(0%, 100%) matrix(1, 0.1, 0, 1, 0, 0)',opacity: 1, zIndex: 999 }),
       ])
      )]),
      // cubic-bezier(.72,.01,.03,.87)
      transition('closed => open', [animate('1.5s 0.3s cubic-bezier(.25,.1,.25,1)',
        keyframes([
          style({ transform: 'translate(0%, -120%) matrix(1, 0, 0, 1, 0, 0)',opacity: 0, zIndex: -99999 }),
          style({ transform: 'translate(0%, -120%) matrix(1, 0, 0, 1, 0, 0)',opacity: 1, zIndex: 999 }),
          style({ transform: 'translate(0%, -60%) matrix(7.9, 1, 0, 3.7, 0, 0)',opacity: 1, zIndex: 999 }),
          style({ transform: 'translate(0%, 100%) matrix(1, 0.1, 0, 1, 0, 0)',opacity: 1, zIndex: 999 }),
        ])
      )])
    ]),
  ]
})


export class FillerAnimationComponent implements OnInit {

  @Input() logging = false;
  isOpen;
  isGopen;

  constructor(private service: Service) { }
  ngOnInit() {
    this.service.toggle$.subscribe(
      animationToggle => this.isOpen = animationToggle
    )

    this.service.toggle$.subscribe(
      animationToggle => this.isOpen = animationToggle
    )
  }

  animationToggle() {
    this.isOpen = !this.isOpen;
  }

  onAnimationEvent( event: AnimationEvent ) {
    if (!this.logging) {
      return;
    }


  }




}
