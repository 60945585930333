<section class="sectionText pageInner">

  <div class="innerScroll" fxLayout="column" fxLayoutAlign="center center">

    <br><br>
    <img class="title" src="../../assets/new-tit-zekiS.png" />
    <br><br>
    <img class="p2" src="../../../assets/introtozeki.png" />
    <br><br>
    <p class="p2" i18n #text [innerHTML]="'mainmenu.zeki-t1' | translate"></p>
    <br><br>

    <img class="p2" src="../../../assets/pakize_zeki.png" />

    <br><br>
    <p class="p2" i18n [innerHTML]="'mainmenu.zeki-t2' | translate"></p>
    <br><br>

    <img class="p2" src="../../../assets/zeki-heralde.png" />

    <br><br>
    <p class="p2" i18n [innerHTML]="'mainmenu.zeki-t3' | translate"></p>
    <p><br></p>
    <p><br></p>
    <div class="container">
      <iframe class="responsive-iframe" src="https://www.youtube.com/embed/ygooyQUJ8rA"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <p class="caption">
      <small class="p2" i18n [innerHTML]="'mainmenu.zeki-t4' | translate"></small>
    </p>



    <p><br></p>
    <p><br></p>

    <!-- <iframe src="https://open.spotify.com/embed/playlist/48wozWqWzbVdTvH2jxDs5L" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    <p><br></p>
    <iframe src="https://open.spotify.com/embed/playlist/2RiEt6H95BLgE8W8NyovN2" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    <p><br></p>
    <iframe src="https://open.spotify.com/embed/playlist/4nh7gTEyQchdZZU4U3VQDe" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> -->
  </div>


</section>
