<mat-toolbar *ngIf="!hide">
  <div fxFlex fxLayout fxLayoutAlign="center" fxHide.xs fxHide.gt-xs fxShow.gt-sm >
    <ul fxLayout fxLayoutGap="30px" class="navigation-items menu">
      <li class='poster'>
        <a id="poster" class="{{message}}" (mousedown)="linkPoster()" (mouseup)="startFillerAnimation()" i18n>{{ 'mainmenu.link1' | translate }} </a>
      </li>
      <li class='madi'>
        <a id="madi" class="{{message}}" (mousedown)="linkMadi()" (mouseup)="startFillerAnimation()" i18n>{{ 'mainmenu.link2' | translate }} </a>
      </li>
      <li class='zeki'>
        <a id="zeki" class="{{message}}" (mousedown)="linkZeki()" (mouseup)="startFillerAnimation()" >{{ 'mainmenu.link3' | translate }} </a>
      </li>
      <li class='huysuz'>
        <a id="huysuz" class="{{message}}" (mousedown)="linkHuysuz()" (mouseup)="startFillerAnimation()" i18n>{{ 'mainmenu.link5' | translate }} </a>
      </li>
      <li class='bulent'>
        <a id="bulent" class="{{message}}" (mousedown)="linkBulent()" (mouseup)="startFillerAnimation()">BÜLENT ERSOY </a>
      </li>
      <li class='podcast'>
        <a id="podcast" class="{{message}}" (mousedown)="linkPodcast()" (mouseup)="startFillerAnimation()" i18n>{{ 'mainmenu.link6' | translate }} </a>
      </li>
      <li class='lubunya'>
        <a id="lubunya" class="{{message}}" (mousedown)="linklubunya()" (mouseup)="startFillerAnimation()"i18n>{{ 'mainmenu.link7' | translate }} </a>
      </li>

      <li style="display: flex;flex-direction: row;align-items: start;margin-top: -13px;">
        <a id="spotfy" class="social" target="_blank" href="https://open.spotify.com/show/2VHAepNcWptajVt4BcT8VM?go=1&utm_source=embed_v3&t=0&nd=1"></a>

       <a id="instagram" class="social" target="_blank" href="https://www.instagram.com/madiancestors"></a>

       <a id="email" class="social" [@flyInOut] href="mailto:tikel@madiancestors.com"></a>


        <select id="lang" #langSelect (change)="translate.use(langSelect.value)">
          <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
        </select>

      </li>


    </ul>
  </div>

</mat-toolbar>


<div fxLayout="column" fxLayoutAlign="start end" id="sortbar">
  <button  class="sortbar" mat-icon-button (click)="onToggleSidenav()" fxHide.gt-sm>
    <mat-icon class="unslct">sort</mat-icon>
  </button>
</div>
