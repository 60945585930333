import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Service } from '../service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.css']
})
export class Page404Component implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  constructor(private service: Service){}

  ngOnInit(): void {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("404")
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
