
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { one } from'../../blowAnimation'
import { Subscription } from 'rxjs';
import { Service } from '../../service';

@Component({
  selector: 'app-huysuz',
  templateUrl: './huysuz.component.html',
  styleUrls: ['./huysuz.component.css'],
  animations: [one]
})
export class HuysuzComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;


  public animatePage = true;

  constructor(private service: Service, private router: Router) { }

  public ngOnInit(): void {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("huysuz")

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



}
