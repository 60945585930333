import { Component, OnInit, OnDestroy } from '@angular/core';
import { one } from'../../blowAnimation'
import { Subscription } from 'rxjs';
import { Service } from '../../service';

@Component({
  selector: 'app-bulent',
  templateUrl: './bulent.component.html',
  styleUrls: ['./bulent.component.css'],
  animations: [one]

})
export class BulentComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  public animatePage = true;
  constructor(private service: Service){}

  ngOnInit(): void {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("bulent")
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
