




<mat-sidenav-container>


    <mat-sidenav #sidenav [@navigation]="navigation" ><label>



    </label>
      <app-sidenav-list (closeSidenav)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>

  <mat-sidenav-content>
    <div id="menuFiller" [@showOverlay]="showOverlay"><p>&nbsp;</p></div>
    <app-header id="navbar" (sidenavToggle)="sidenav.toggle()"></app-header>

    <app-filler-animation></app-filler-animation>


    <main class="content">
      <router-outlet></router-outlet>
    </main>



  </mat-sidenav-content>

</mat-sidenav-container>








