

<mat-nav-list fxLayout="column" fxLayoutAlign="center center">
  <a (click)="onClose()">
    <mat-icon>clear</mat-icon>
  </a>
  <div fxFlex="8">
    <p>&nbsp;</p>
    <!-- üstboşluk -->
  </div>
    <a class="poster" id="madilikLink" (click)="onClose()" (mousedown)="linkPoster()" #link *ngIf="show" [@flyInOut]>
      <span class="nav-caption {{message}}" i18n>{{ 'mainmenu.link1' | translate }}</span>
    </a>
    <a class="madi" id="madilikLink" (click)="onClose()" (mousedown)="linkMadi()" #link *ngIf="show" [@flyInOut]>
          <span class="nav-caption {{message}}" i18n>{{ 'mainmenu.link2' | translate }}</span>
        </a>

    <div fxFlex="7">
      <p>&nbsp;</p>
      <!-- 2ndBoşluk -->
    </div>

    <a class="plak zeki" (click)="onClose()" (mousedown)="linkZeki()" #plak *ngIf="show" [@flyInOut]>
      <span class="nav-caption {{message}} idol">{{ 'mainmenu.link3' | translate }}</span>
    </a>

    <a class="plak bulent" (click)="onClose()" (mousedown)="linkBulent()" #plak *ngIf="show" [@flyInOut]>
      <span class="nav-caption {{message}} idol">{{ 'mainmenu.link4' | translate }}</span>
    </a>

    <a class="plak huysuz" (click)="onClose()" (mousedown)="linkHuysuz()" #plak *ngIf="show" [@flyInOut]>
      <span class="nav-caption {{message}} idol">{{ 'mainmenu.link5' | translate }}</span>
    </a>

    <div fxFlex="7">
      <p>&nbsp;</p>
      <!-- 3rdboşluk -->
    </div>

    <a class="podcast" fxFlex="3" id="podcast" (click)="onClose()" (mousedown)="linkPodcast()" #link *ngIf="show" [@flyInOut] class="flyInOut">
      <span class="nav-caption {{message}}" i18n>{{ 'mainmenu.link6' | translate }}</span>
    </a>
    <div fxFlex="3">
      <p>&nbsp;</p>
      <!-- menuSonuboşluk -->
    </div>

    <a id="lubunya" class="lubunya" fxFlex="3" (click)="onClose()" (mousedown)="linkLubunya()" #link *ngIf="show" [@flyInOut] class="flyInOut">
      <span class="nav-caption {{message}}">{{ 'mainmenu.link7' | translate }}</span>
    </a>

    <a id="lubun" class="lubun" fxFlex="3" (click)="onClose()" (mousedown)="linkLubun()" #link *ngIf="show" [@flyInOut] class="flyInOut">
      <span class="nav-caption {{message}}">{{ 'mainmenu.link7' | translate }}</span>
    </a>

    <div fxFlex="8">
      <p>&nbsp;</p>
      <!-- menuSonuboşluk -->
    </div>




      <ul id="lang" #langSelect fxLayout="row" fxLayoutAlign="center center" fxFlex="3" fxLayout="row" *ngIf="show" [@flyInOut] class="" style="margin-left: -30px;">
        <li *ngFor="let lang of translate.getLangs()" (click)="translate.use(lang)" class="lang-link {{translate.currentLang}}"><span class="{{ lang }}">{{ lang }}</span></li>
      </ul>


    <div fxFlex="1">
      <p>&nbsp;</p>
      <!-- menuSonuboşluk -->
    </div>

    <div fxFlex="3" fxLayout="row" fxLayoutAlign="center center" #social>
      <a id="spotfy" class="social" *ngIf="show" [@flyInOut] target="_blank" href="https://open.spotify.com/show/2VHAepNcWptajVt4BcT8VM?go=1&utm_source=embed_v3&t=0&nd=1"></a>
      <a id="instagram" class="social"  *ngIf="show" [@flyInOut] target="_blank" href="https://www.instagram.com/madiancestors"></a>
      <a id="email" class="social"  *ngIf="show" [@flyInOut] href="mailto:tikel@madiancestors.com"></a>
    </div>

    <div fxFlex="16.7">
      <p>&nbsp;</p>
      <!-- Sonboşluk -->
    </div>


</mat-nav-list>


<div id="menuBg"
      *ngIf="show"
      [@flyInOut]
      [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}" >
      <div style="background-color: #ffffff70;height: 100%;"><br></div>
</div>
