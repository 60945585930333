<section class="sectionText pageInner">

  <div class="innerScroll" fxLayout="column" fxLayoutAlign="space-evenly center">


    <img class="title" i18n-src src="{{'mainmenu.madiliktitle' | translate}}" />


    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutAlign.xs='center center' id="ghostCase">
      <img class="ghost1" src="../../assets/gost1.png" fxFlex="50" />
      <img class="ghost2" src="../../assets/gost2.png" fxFlex="50" />
      <img class="ghost3" src="../../assets/goast3.png" fxFlex="50" />
    </div><br><br>

    <p class="p2" i18n [innerHTML]="'mainmenu.t1' | translate"></p><br>

    <img class="p2" src="../../assets/madilik1.png" /><br>

    <p class="p2" i18n [innerHTML]="'mainmenu.t3' | translate"></p><p><br></p>

    <img class="p2" src="../../assets/bigudiS.png" /><p><br></p>

    <p class="p2" i18n [innerHTML]="'mainmenu.t4' | translate"></p>
    <p class="p2" i18n [innerHTML]="'mainmenu.t5' | translate"></p><br><br>

    <img class="p2" src="../../assets/bigudiS2.png" />

    <div class="framedText" i18n [innerHTML]="'mainmenu.t6' | translate"></div>
    <p class="p2" i18n [innerHTML]="'mainmenu.t7' | translate"></p>

    <div class="algnrght" style="text-align: right;">
      <p class="p2" i18n [innerHTML]="'mainmenu.t8' | translate"></p>
    </div>

    <br><br>

    <div class="divider"><span></span><span> * * * </span><span></span></div>
    <br><br>
    <blockquote>
      <p class="p2" i18n [innerHTML]="'mainmenu.t9' | translate"></p>
    </blockquote>
    <br><br><br>


    <blockquote>
      <p class="p2" i18n [innerHTML]="'mainmenu.t10' | translate"></p>
    </blockquote>
    <br><br><br>


    <blockquote>
      <p class="p2" i18n [innerHTML]="'mainmenu.t11' | translate"></p>
    </blockquote>

    <br><br><br><br><br><br>


  </div>


</section>

<div class="check"> <br></div>
