<section id="podcastSection" class="sectionText pageInner">

<div id="eFrame" style="height:90vh; padding: 2vh 9vw;">

  <div class="podcastDesc" >
    <p class="p2" i18n [innerHTML]="'mainmenu.podcast0' | translate"></p><br>
  </div>


  <iframe src="https://open.spotify.com/embed-podcast/episode/3mbET9ZlLK7sCs2cYRXRps" frameborder="0" allowtransparency="true" height="232" allow="encrypted-media" style="height: 232px !important;">
  </iframe>
  <div class="podcastDesc" >
    <p class="p2" i18n [innerHTML]="'mainmenu.podcast1' | translate"></p><br>
  </div>


  <iframe src="https://open.spotify.com/embed-podcast/episode/5eQzdq4tVuu7B9WC7CV6hd" frameborder="0" allowtransparency="true" height="232" allow="encrypted-media" style="height: 232px !important;">
  </iframe>
  <div class="podcastDesc" >
    <p class="p2" i18n [innerHTML]="'mainmenu.podcast2' | translate"></p><br>
  </div>


  <iframe src="https://open.spotify.com/embed-podcast/episode/2zx2XjBUCye5f2N7GPT3Ew" frameborder="0" allowtransparency="true" height="232" allow="encrypted-media" style="height: 232px !important;">
  </iframe>
  <div class="podcastDesc" >
    <p class="p2" i18n [innerHTML]="'mainmenu.podcast3' | translate"></p><br>
  </div>

<iframe src="https://open.spotify.com/embed-podcast/episode/10M8hnnbNLokE4veuv3Dod" frameborder="0" allowtransparency="true" height="232" allow="encrypted-media" style="height: 232px !important;">
  </iframe>
  <div class="podcastDesc" >
    <p class="p2" i18n [innerHTML]="'mainmenu.podcast4' | translate"></p><br>
  </div>


</div>


</section>


