import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Service } from '../app/service';
import {enableProdMode} from '@angular/core';
import {trigger,state,style,animate,transition,keyframes} from '@angular/animations';
enableProdMode();


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [



    trigger("navigation", [

      state('false' , style({opacity: 0})),
      state('true', style({opacity: 1})),

      transition('false => true', [
        animate('1.5s cubic-bezier(.72,.01,.03,.87)')
      ]),

      transition('true => false', [
        animate('1.5s cubic-bezier(.72,.01,.03,.87)')
      ]),

    ]),


    trigger("showOverlay", [

      state('false' , style({transform: 'translate3d(120%, 0, 0) matrix(1, 0, 0, 1, 0, 0) scale(1.4)'})),
      state('true', style({transform: 'translate3d(0%, 0, 0) matrix(1, 0, 0, 1, 0, 0) scale(1)'})),

      transition('true => false', [
        animate('1s cubic-bezier(.72,.01,.03,.87)',
         keyframes([
          style({ transform: 'translate3d(0%, 0, 0) matrix(1, 0, 0, 1, 0, 0) scale(1.4)'}),
          style({ transform: 'translate3d(-60%, 0, 0) matrix(1.1, 0, 0.3, 1.3, 0, 0.4) scale(1.2)' }),
          style({ transform: 'translate3d(-120%, 0, 0) matrix(1, 0, 0, 1, 0, 0) scale(1)' })
          ])
        )
      ]),

      transition('false => true', [
        animate('1s cubic-bezier(.72,.01,.03,.87)',
         keyframes([
          style({ transform: 'translate3d(120%, 0, 0) matrix(1, 0, 0, 1, 0, 0) scale(1)' }),
          style({ transform: 'translate3d(60%, 0, 0) matrix(1.1, 0, 0.3, 1.3, 0, 0.4) scale(1.2)' }),
          style({ transform: 'translate3d(0%, 0, 0) matrix(1, 0, 0, 1, 0, 0) scale(1.4)' })
          ])
        )
      ]),

    ])






  ]
})


export class AppComponent implements AfterViewInit, OnInit {


  navigation:boolean=true;
  showOverlay:boolean=true;


  ngOnInit() {

    this.service.playMobilMobil$.subscribe(mobilMobilMenu => this.navigation = mobilMobilMenu)
    this.service.playMobilMobil$.subscribe(mobilMobilMenu => this.showOverlay = mobilMobilMenu)
    this.showOverlay=false;

  }

  constructor(private service: Service) {

  }

  ngAfterViewInit() { this.showOverlay = false; this.navigation = false; }
}
