import { Component, OnInit, OnDestroy } from '@angular/core';
import { Service } from '../service';
import { one } from'../blowAnimation'
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-podcasts',
  templateUrl: './podcasts.component.html',
  styleUrls: ['./podcasts.component.css'],
  animations: [one]
})
export class PodcastsComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  public animatePage = true;
  constructor(private service: Service){}


ngOnInit(): void {
  this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("podcast")
 }

ngOnDestroy() {
  this.subscription.unsubscribe();
}







}
