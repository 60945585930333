<div id="mobile">

  <!-- leman -->
        <img class="popimage" src="../../assets/a-lubunyalar-leman.png" />
        <img class="popMtitle" src="../../assets/b-title-leman.png" style="margin-top: -152px; max-width: 120%;" />
        <p [innerHTML]="'mainmenu.lubun-leman' | translate"></p>
        <a class="bioLink" href="http://lemandaricioglu.com" target="_blank">www.lemandaricioglu.com</a>

  <!-- yener -->
        <img class="popimage" src="../../assets/a-lubunyalar-yener.png" />
        <img class="popMtitle" src="../../assets/b-title-yener.png" style="margin-top: -192px; max-width: 140%; margin-left: 99px;"  />
        <p [innerHTML]="'mainmenu.lubun-yener' | translate"></p>

  <!-- gizem -->
        <img class="popimage" src="../../assets/a-lubunyalar-gizem.png" />
        <img class="popMtitle" src="../../assets/b-title-gizem.png" style="margin-top: -252px; max-width: 150%;"  />
        <p>
            <span [innerHTML]="'mainmenu.lubun-gizem1' | translate"></span>
            <a class='bioLink' href='www.6zm.xyz'  target='_blank'>www.6zm.xyz</a>
            <span [innerHTML]="'mainmenu.lubun-gizem2' | translate"></span>
            <a class='bioLink' href='www.gazino.de' target='_blank'>www.gazino.de</a>
            <span [innerHTML]="'mainmenu.lubun-gizem3' | translate"></span>
            <a class="bioLink" href="www.gizemoruc.net" target="_blank">www.gizemoruc.net</a>
          </p>

  <!-- ekin -->
        <img class="popimage" src="../../assets/a-lubunyalar-ekin.png" />
        <img class="popMtitle" src="../../assets/b-title-ekin.png" style="margin-top: -332px; max-width: 165%; margin-left: 28px;"  />
          <p>
            <span [innerHTML]="'mainmenu.lubun-ekin1' | translate"></span>
            <a class="bioLink" href="www.bedencalismalari.com"  target="_blank">www.bedencalismalari.com</a>
            <span [innerHTML]="'mainmenu.lubun-ekin2' | translate"></span>
            <a class="bioLink" href="www.sodacommunicationdesign.com"  target="_blank">www.sodacommunicationdesign.com</a>
            <span [innerHTML]="'mainmenu.lubun-ekin3' | translate"></span>
          </p>

  <!-- anthony -->
        <img class="popimage" src="../../assets/a-lubunyalar-anthony.png" />
        <img class="popMtitle" src="../../assets/b-title-anthony.png" style="margin-top: -202px; max-width: 180%; margin-left: 100px;"  />
          <p [innerHTML]="'mainmenu.lubun-ant' | translate"></p>

  <!-- ari -->
        <img class="popimage" src="../../assets/a-lubunyalar-ari.png" />
        <img class="popMtitle" src="../../assets/b-title-ari.png" style="margin-top: -172px; max-width: 180%; margin-left: 150px;"  />
          <p>
            <span  [innerHTML]="'mainmenu.lubun-ari1' | translate"></span>
            <a class="bioLink" href="http://www.velvele.net" target="_blank"><strong>Velvele.net</strong></a>
            <span  [innerHTML]="'mainmenu.lubun-ari2' | translate"></span>
          </p>
          <a class="bioLink" href="http://www.velvele.net" target="_blank">velvele.net</a>

  <!-- besire -->
        <img class="popimage" src="../../assets/a-lubunyalar-besire.png" />
        <img class="popMtitle" src="../../assets/b-title-besire.png" style="margin-top: -242px; max-width: 200%; margin-left: 220px;"  />
          <p [innerHTML]="'mainmenu.lubun-besire' | translate"></p>
          <a class="bioLink" href="vimeo.com/paralik" target="_blank">vimeo.com/paralik</a><br>

  <!-- gulay -->
        <img class="popimage" src="../../assets/a-lubunyalar-gulay.png" />
        <img class="popMtitle" src="../../assets/b-title-gulay.png" style="margin-top: -202px; max-width: 190%; margin-left: -90px;"  />
          <p [innerHTML]="'mainmenu.lubun-gulay' | translate"></p>

  <!-- ilgaz -->
        <img class="popimage" src="../../assets/a-lubunyalar-ilgaz.png" />
        <img class="popMtitle" src="../../assets/b-title-ilgaz.png" style="margin-top: -182px; max-width: 150%; margin-left: 80px;"  />
          <p [innerHTML]="'mainmenu.lubun-ilgaz' | translate"></p>

  <!-- ipek -->
        <img class="popimage" src="../../assets/a-lubunyalar-ipek.png" />
        <img class="popMtitle" src="../../assets/b-title-ipek.png" style="margin-top: -209px; max-width: 142%; margin-left: 48px;"  />
          <p>
            <span  [innerHTML]="'mainmenu.lubun-ipek1' | translate"></span>
            <a class="bioLink" href="www.gladt.de" target="_blank"><strong>Gladt e.V.</strong></a>
            <span  [innerHTML]="'mainmenu.lubun-ipek3' | translate"></span>
          </p>
              <a class="bioLink" href="www.djipek.com" target="_blank">www.djipek.com</a><br><br>
              <p><small>IPEK Sounddesign & Booking & Artist Management İpek İpekçioğlu<br>
              <a class="bioLink" href="" target="_blank">Facebook</a> /
              <a class="bioLink" href="" target="_blank">Twitter</a> /
              <a class="bioLink" href="https://www.youtube.com/user/djipek" target="_blank">youtube</a> /
              <a class="bioLink" href="" target="_blank">Soundcloud</a> /
              <a class="bioLink" href="https://ra.co/dj/djipek" target="_blank">Ra</a> /
              <a class="bioLink" href="" target="_blank">mail@djipek.com</a> /
              <a class="bioLink" href="" target="_blank">Bandcamp</a></small>
          </p>
  <!-- merve -->
        <img class="popimage" src="../../assets/a-lubunyalar-merve.png" />
        <img class="popMtitle" src="../../assets/b-title-merve.png" style="margin-top: -249px; max-width: 130%;"  />
          <p [innerHTML]="'mainmenu.lubun-merve' | translate"></p>
          <a class="bioLink" href="https://blogs.taz.de/dontwannabetoocool/" target="_blank">blogs.taz.de/dontwannabetoocool</a><br><br>

  <!-- pirilti -->
        <img class="popimage" src="../../assets/a-lubunyalar-pirilti.png" />
        <img class="popMtitle" src="../../assets/b-title-pirilti.png" style="margin-top: -239px; max-width: 117%; margin-left: -4px;"  />
          <p [innerHTML]="'mainmenu.lubun-pirilti' | translate"></p>
          <a class="bioLink" href="https://vimeo.com/pirilti" target="_blank">vimeo.com/pirilti</a><br>

  <!-- tulin -->
        <img class="popimage" src="../../assets/a-lubunyalar-tulin.png" />
        <img class="popMtitle" src="../../assets/b-title-tulin.png" style="margin-top: -269px; max-width: 140%; margin-left: 5px;"  />
          <p [innerHTML]="'mainmenu.lubun-tulin' | translate"></p>
          <a class="bioLink" href="https://soundcloud.com/queeriental" target="_blank">T**lin (queeriental) - soundcloud.com</a><br>
          <a class="bioLink" href="https://www.facebook.com/queeriental" target="_blank">Queeriental - facebook.com</a><br>

</div>






