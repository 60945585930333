import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { RouterLinkDelayModule } from '@bcodes/ngx-routerlink-delay';



import { BulentComponent } from './gazino/bulent/bulent.component';
import { HuysuzComponent } from './gazino/huysuz/huysuz.component';
import { ZekiComponent } from './gazino/zeki/zeki.component';
import { PodcastsComponent } from './podcasts/podcasts.component';
import { MadilikComponent } from './madilik/madilik.component';
import { LubunyaComponent } from './lubunya/lubunya.component';
import { LubunComponent } from './lubun/lubun.component';
import { PosterComponent } from './gazino/poster/poster.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { HeaderComponent } from './navigation/header/header.component';
import { FillerAnimationComponent } from './filler-animation/filler-animation.component';

import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { Page404Component } from './page404/page404.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}



@NgModule({
  declarations: [
    AppComponent,
    BulentComponent,
    HuysuzComponent,
    ZekiComponent,
    PodcastsComponent,
    MadilikComponent,
    LubunyaComponent,
    LubunComponent,
    PosterComponent,
    SidenavListComponent,
    HeaderComponent,
    FillerAnimationComponent,
    Page404Component





   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterLinkDelayModule,
    AngularFullpageModule

  ],
  providers: [{ provide: LOCALE_ID, useValue: 'tr, en, de' }],
  bootstrap: [AppComponent]
})



export class AppModule {

 }
