import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { one } from'../blowAnimation'
import { Subscription } from 'rxjs';
import { Service } from '../service';


@Component({
  selector: 'app-lubun',
  templateUrl: './lubun.component.html',
  styleUrls: ['./lubun.component.css'],
  animations: [one]
})
export class LubunComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;


  constructor(private renderer: Renderer2, private service: Service) {}

  ngOnInit() {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("lubun");
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }







}

