<main class="pageInner">

  <section class="section section-1" fxLayout="column" fxLayoutAlign="center center">

    <div fxFlex="15"></div>
    <img class="poster" i18n-src src="{{'mainmenu.goldenBerlin' | translate}}" fxFlex.xs="45" fxFlex.sm="50" fxFlex.md="54" fxFlex.lg="63" fxFlex.xl="67" />


    <div class="logoSpace stripe" fxFlex="21" fxLayout="column">
          <div fxFlex="12" fxLayout="raw" fxShow.gt-xs fxHide.xs></div>
          <div fxFlex="86" fxLayout="raw" fxLayoutAlign="space-evenly center" >
            <img class="logo wBg" src="{{'mainmenu.glad' | translate}}" fxFlex="8"/>

            <img class="logo" src="../../../assets/logo/PfD-Logo-RZ-blau-72.png" fxFlex="6"  />
            <img class="logo wBg" src="../../../assets/logo/SEN_JuVeAn_logo_hoch.png"  fxFlex="7.2" />
            <img class="logo wBg" src="../../../assets/logo/BMFSFJ_DL_mitFoerderzusatz.png" fxFlex="17" />
            <img class="logo wBg" src="../../../assets/logo/wappen-fk-klein.png" fxFlex="4" />
            <img class="logo wBg" src="../../../assets/logo/interflugs_logo-01.png"  fxFlex="6" />
          </div>
          <div fxFlex="12" fxLayout="raw" fxHide.gt-xs fxShow.xs></div>

    </div>

  </section>







</main>

