import { Component, OnInit, OnDestroy } from '@angular/core';
import { one } from'../../blowAnimation'
import { Service } from '../../service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-poster',
  templateUrl: './poster.component.html',
  styleUrls: ['./poster.component.css'],
  animations: [one]
})


export class PosterComponent implements OnInit, OnDestroy  {

  message:string;
  subscription: Subscription;


  public animatePage = true;



  constructor(private service: Service){}


  ngOnInit(): void {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("poster")
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }




}
