import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/compiler';

import { AppModule } from './app/app.module';
import {enableProdMode} from '@angular/core';
import { environment } from './environments/environment';

if (environment.production) {enableProdMode();}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


