import { Component, OnInit, OnDestroy } from '@angular/core';
import { one } from'../blowAnimation'
import { Subscription } from 'rxjs';
import { Service } from '../service';

@Component({
  selector: 'app-madilik',
  templateUrl: './madilik.component.html',
  styleUrls: ['./madilik.component.css'],
  animations: [one]
})
export class MadilikComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  public animatePage = true;
  constructor(private service: Service){}

  ngOnInit(): void {
    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("madi")
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
