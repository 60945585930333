import { Component, OnInit, OnDestroy } from '@angular/core';
import { one} from'../../blowAnimation'
import { Subscription } from 'rxjs';
import { Service } from '../../service';

@Component({
  selector: 'app-zeki',
  templateUrl: './zeki.component.html',
  styleUrls: ['./zeki.component.css'],
  animations: [one]
})
export class ZekiComponent implements OnInit, OnDestroy {

  message:string;
  subscription: Subscription;

  constructor(private service: Service){}

  public animatePage = true;


  ngOnInit(): void {

    this.subscription = this.service.currentMessage.subscribe(message => this.message = message)
    this.service.changeMessage("zeki")
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



}
